<template>
  <div v-if="pickban" class="ban">
    <template v-if="needWait">
      <p class="pickban-title">
        {{ $t('matches.wf_pickban_startIn') }}:
      </p>
      <div class="time-left">
        <Timer
          :key="getRandomId()"
          :initial="pickban.pickBanStartTime"
          format="mm:ss"
          countdown
          @timeout="onBeforeMapPickTimeout"
        />
      </div>
    </template>
    <template v-else>
      <template v-if="isPickbanInProgress">
        <template v-if="role.captain">
          <p class="pickban-title">{{ pickbanTitle }}</p>
          <template
            v-if="
              pickban.expired >= timeStore.serverTime &&
              !loadingState.mapPick
            "
          >
            <div class="time-left">
              <Timer
                :key="getRandomId()"
                :initial="pickban.expiredFull.ts"
                format="mm:ss"
                countdown
                @timeout="onBeforeMapPickTimeout"
              />
            </div>
            <p class="ban-desc">
              {{ $t('matches.wf_pickban_autoChoose') }}
            </p>
          </template>
        </template>
        <p
          v-else-if="!role.judge && !role.participant"
          class="pickban-title"
        >
          {{ $t(`matches.wf_pickban_status_wait`) }}
        </p>
      </template>

      <loader v-if="loadingState.mapPick" />
      <template v-else>
        <div v-if="isShowServerIsLoading" class="mb-20">
          <p class="mb-20">Идет подготовка сервера к матчу</p>
          <loader></loader>
        </div>
        <div v-if="isMapsVisible" class="pickban-list">
          <div
            v-for="(map, key) in maps.picked"
            :key="`picked-${key}`"
            class="pickban-box picked"
          >
            <span class="sort">{{ key + 1 }}</span>
            <i18n
              path="matches.wf_pickban_team_status"
              class="picked-title"
            >
              <BaseTeamLink
                slot="team"
                :hash="map.teamHash"
                :display-image="false"
              />
              <b slot="action" class="action">
                {{ $t('matches.wf_pickban_team_picked') }}
              </b>
              <b slot="map" class="map">{{ map.name }}</b>
            </i18n>

            <div class="side">
              <button
                v-for="(side, index) in map.sides"
                :key="index"
                class="side-box"
                :class="[
                  side.code,
                  {
                    picked:
                      side.code === sideCode && map.code === mapCode,
                  },
                ]"
                :disabled="!pickban.current || !needPickSide"
                @click="
                  !side.teamHash && setMapInfo(map.code, side.code)
                "
              >
                <icon
                  group="games"
                  :class="side.code"
                  :name="side.icon"
                />
                <span class="label">{{ side.label }}</span>
                <BaseTeamLink
                  v-if="side.teamHash"
                  :hash="side.teamHash"
                />
              </button>
            </div>
          </div>

          <button
            v-for="(map, key) in maps.unselected"
            :key="key"
            class="pickban-box"
            :disabled="!pickban.current || needPickSide"
            :class="{
              picked: mapCode === map.code && pickban.status === 1,
              banned: mapCode === map.code && pickban.status === 2,
            }"
            @click="setMapInfo(map.code)"
          >
            {{ map.name }}
          </button>

          <pvp-btn
            v-if="mapCode"
            :block="true"
            :is-loading="loadingState.confirm"
            @click="banMap"
          >
            <template v-if="needPickSide">
              {{ $t('matches.wf_map_pickSide') }}
            </template>
            <template v-else-if="pickban.status === 1">
              {{ $t('matches.wf_map_pick') }}
            </template>
            <template v-else-if="pickban.status === 2">
              {{ $t('matches.wf_map_ban') }}
            </template>
          </pvp-btn>
        </div>

        <div v-if="maps.banned.length" class="pickban-list banned">
          <p class="pickban-title">
            {{ $t('matches.wf_map_banned') }}
          </p>
          <div
            v-for="(map, key) in maps.banned"
            :key="`banned-${key}`"
            class="pickban-box banned"
          >
            <span class="sort">{{ key + 1 }}</span>
            <i18n path="matches.wf_pickban_team_status">
              <BaseTeamLink
                slot="team"
                :hash="map.teamHash"
                :display-image="false"
              />
              <b slot="action" class="action">
                {{ $t('matches.wf_pickban_team_banned') }}
              </b>
              <b slot="map" class="map">{{ map.name }}</b>
            </i18n>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
import Timer from '@components/v2/Timer.vue';
import BaseTeamLink from '@components/BaseComponents/BaseTeamLink.vue';
import { uniqueId } from 'lodash';
import { useTimeStore } from '@src/shared/store/useTimeStore';

export default {
  name: 'Shooters',
  components: { Timer, BaseTeamLink, Icon },
  props: {
    id: {
      type: Number,
      required: true,
    },
    isShowServerIsLoading: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    const timeStore = useTimeStore();
    timeStore.fetchServerTime();
    return { timeStore };
  },
  data: () => ({
    loadingState: {
      mapPick: false,
      confirm: false,
    },
    mapCode: '',
    sideCode: '',
    catalogue: {},
  }),
  computed: {
    ...mapGetters('matches', ['getMatchPickBan', 'getMatch']),
    ...mapGetters('users', ['getUserMatchStatus']),
    ...mapGetters('tournaments', ['getTournament']),
    ...mapGetters('application', ['getGameCode']),

    gameCode() {
      const match = this.getMatch(this.id);
      const tournament = this.getTournament(match?.idTournament);
      return this.getGameCode(tournament?.idGame);
    },

    gameId() {
      const match = this.getMatch(this.id);
      const tournament = this.getTournament(match?.idTournament);
      return tournament?.idGame;
    },

    isCsGame() {
      const csgoGameId = 24;
      const cs2Code = 'cs2';
      return this.gameId === csgoGameId || this.gameCode === cs2Code;
    },

    role() {
      return this.getUserMatchStatus(this.id)?.roles || {};
    },

    pickban() {
      return this.getMatchPickBan(this.id);
    },

    pickbanTitle() {
      if (!this.pickban.current) {
        return this.$t('matches.wf_pickban_status_waitOpponent');
      }
      if (this.pickban.status === 1) {
        if (this.needPickSide) {
          return this.$t('matches.wf_pickban_status_pickSide');
        }
        return this.$t('matches.wf_pickban_status_pickMap');
      }
      if (this.pickban.status === 2) {
        return this.$t('matches.wf_pickban_status_ban');
      }
      return '';
    },

    needWait() {
      return (
        this.pickban?.pickBanStartTime >= this.timeStore.serverTime
      );
    },

    maps() {
      const obj = {
        picked: [],
        banned: [],
        unselected: [],
      };

      const mapData = _.keyBy(this.catalogue?.maps, 'code');
      const filtered =
        this.pickban?.state?.reduce?.(
          (acc, { code, teamHash, sort, ...map }) => {
            const data = { ...mapData[code], teamHash, sort };
            if (map.picked) {
              const sides = Object.entries(map.sides).reduce(
                (acc, [key, value]) => ({
                  ...acc,
                  [value]: key,
                }),
                {},
              );
              data.sides = this.catalogue?.sides?.map((cls) => ({
                ...cls,
                teamHash: sides[cls.code],
                icon: cls?.code,
              }));
              acc.picked.push(data);
            } else if (map.banned) {
              acc.banned.push(data);
            } else {
              acc.unselected.push(data);
            }

            return acc;
          },
          obj,
        ) || obj;

      filtered.picked = filtered.picked.sort((a, b) =>
        a.sort > b.sort ? 1 : -1,
      );
      filtered.banned = filtered.banned.sort((a, b) =>
        a.sort > b.sort ? 1 : -1,
      );
      return filtered;
    },

    needPickSide() {
      //TODO разобраться зачем нужен needPickSide и переписать этот код?
      if (this.isCsGame) {
        return false;
      }
      return (
        this.maps?.picked?.filter(
          (map) =>
            map?.sides?.filter((side) => side.teamHash).length === 0,
        ).length > 0
      );
    },

    isPickbanInProgress() {
      return [1, 2].includes(this.pickban.status);
    },

    isMapsVisible() {
      return (
        this.pickban?.state?.length &&
        (this.pickban.status === 3 ||
          this.role.captain ||
          this.role.participant ||
          this.role.judge)
      );
    },
  },
  watch: {
    maps() {
      this.mapCode = '';
      this.sideCode = '';
    },
    pickban() {
      this.loadingState.mapPick = false;
    },
  },
  created() {
    try {
      this.fetchWfPickbanStatus(this.id);
      this.fetchMapList(this.gameId).then((data) => {
        if (data) {
          this.catalogue = data;
        }
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  },
  methods: {
    ...mapActions('matches', [
      'fetchWfPickbanStatus',
      'fetchWfPickbanConfirm',
      'fetchMapList',
    ]),
    getRandomId() {
      return uniqueId();
    },
    onBeforeMapPickTimeout() {
      this.loadingState.mapPick = this.role?.captain;
    },
    setMapInfo(code, side = '') {
      if (this.pickban.current) {
        this.mapCode = code;
        this.sideCode = side;
      }
    },
    banMap() {
      this.loadingState.confirm = true;
      let action = this.pickban.canBan ? 'ban' : 'pick';
      if (this.needPickSide) {
        action = 'pick_class';
      }
      this.fetchWfPickbanConfirm({
        action,
        id: this.id,
        value: this.mapCode,
        sideCode: this.sideCode || undefined,
      }).finally(() => {
        this.loadingState.confirm = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ban {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.21);
  @include max-tablet() {
    margin-left: -12px;
    margin-right: -12px;
  }

  &:not(:empty) {
    @include min-tablet() {
      padding: 24px 30px;
    }
    @include max-tablet() {
      padding: 12px 4px;
    }
  }

  &-desc {
    font-size: 14px;
    color: rgba(white, 0.5);
  }

  button.pickban-box {
    &:not(:disabled):hover {
      background-color: rgba($dark-two, 0.8);
    }

    &.picked {
      border-color: $dark-lime-green;
    }

    &.banned {
      border-color: $lightish-red;
    }

    &:disabled {
      cursor: not-allowed;
      color: rgba(white, 0.5);
    }
  }

  .button-block {
    margin-top: 20px;
  }
}

.time-left {
  font-size: 30px;
  color: #2390df;
}

.loader {
  margin-top: 1em;
}

.pickban {
  &-title {
    font-size: 18px;
    font-weight: bold;
  }

  &-list {
    max-width: 480px;
    margin: 1em auto 0;

    &.banned {
      margin-top: 40px;

      .pickban {
        &-title {
          margin-bottom: 1em;
        }

        &-box {
          color: rgba(white, 0.35);
          padding-left: 42px;

          .action {
            color: $lightish-red;
          }
        }
      }
    }
  }

  &-box {
    width: 100%;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(white, 0);
    background-color: rgba($dark-two, 0.4);
    padding: 16px 12px;
    font-size: 15px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;

    & + & {
      margin-top: 12px;
    }

    .team-link {
      font-weight: bold;
    }

    .map {
      color: white;
    }

    &.picked {
      color: rgba(white, 0.35);

      .action {
        color: $dark-lime-green;
      }
    }

    .picked-title {
      padding: 0 2em;
    }

    .sort {
      position: absolute;
      left: 14px;
      width: 2em;
      height: 2em;
      background: $charcoal-grey-two;
      color: white;
      display: block;
      line-height: 2em;
      border-radius: 50%;
      top: 2.2em;
      margin-top: -1em;
      font-size: 12px;
    }
  }
}

.side {
  border-top: 1px solid;
  margin-top: 1em;
  width: 100%;
  @include min-tablet() {
    display: flex;
  }

  &-box {
    background-color: rgba($slate, 0.7);
    margin-top: 8px;
    min-height: 38px;
    border-radius: 4px;
    width: 100%;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    color: white;
    border: none;
    display: flex;
    align-items: center;
    overflow: hidden;
    font-size: 14px;
    padding: 8px;

    @include min-tablet() {
      & + & {
        margin-left: 12px;
      }
    }

    .icon {
      flex-shrink: 0;
      @include max-tablet() {
        font-size: 20px;
      }
    }

    &.global_risk,
    &.warface {
      .icon {
        margin-right: 0.5em;

        &.warface {
          color: #186bed;
        }

        &.global_risk {
          color: #7c8394;
          font-size: 20px;
        }
      }

      .label {
        margin-right: 20px;
        white-space: nowrap;
      }
    }

    .team-link {
      @include max-tablet() {
        width: 50%;
        margin-left: auto;
        text-align: left;
      }
    }

    &.black_list,
    &.blackwood {
      @include min-tablet() {
        justify-content: flex-end;
      }

      .icon {
        &.black_list {
          color: #8e785b;
          font-size: 20px;
        }

        &.blackwood {
          color: #ec131a;
        }

        @include min-tablet() {
          margin-left: 0.5em;
          order: 2;
        }
        @include max-tablet() {
          margin-right: 0.5em;
        }
      }

      .label {
        white-space: nowrap;
        @include min-tablet() {
          margin-left: 20px;
          order: 1;
        }
      }
    }

    &.picked {
      border: 1px solid $dark-lime-green;
    }

    &:disabled {
      color: rgba(white, 0.5);
    }
  }
}
.mb-20 {
  margin-bottom: 20px;
}
</style>
